// import google from 'google';
// import BMap from "BMap";
import GPS from '@/utils/coordinate'
import trackIcon from '../../assets/image/track/track_point.png';
import centerIcon from '../../assets/image/map/icon-center.png';
import emptyIcon from '../../assets/image/map/icon-empty.png';
function GoogleMap() {
    this.map = null;
    this.infoWindow = null; //信息窗
    this.polyLine = null; //轨迹
    this.trackIndex = 0; //当前轨迹点
    this.allPoints = [];//所有数据点
    this.trackData = [];//已绘轨迹点
    this.deviceMarker = null;//设备点
    this.timer = null; //定时器，轨迹播放
    this.trackDomInfo = null;//信息窗口dom
    this.tempPoint = [];//原始轨迹点（包含对应点的各种信息）
    this.trackPlaySpeed = 1;//轨迹播放速度
    this.trackCallBack = null;//轨迹播放回调函数
    this.markers = [];
    this.allOverlays = [];//存储所有marker
    this.playMode = 0; //判断是否是打点过后的轨迹播放
    this.fenceArr = [];//存储围栏
    this.drawingManager = null; //绘制
    this.drawinglength = '';//测距
    this.drawTip = false;//鼠标移动施法显示绘制提示
    this.editFence = '';//存储编辑的围栏
    this.editMarkerCenter = '';//存储编辑的围栏的中心点
    this.editMarker = '';//存储编辑围栏的点
    this.editPath = '';//半径线
    this.bubbleData = {};//当前气泡数据
}
/** 初始化谷歌地图
 * @param {String} id 地图id
 * @param {Object} point 初始中心点
 * @param {Number} zoom 地图初始级别
 * @param {Object} opt 地图配置
 */
GoogleMap.prototype.initMap = function (id, point, zoom, opt) {
    let baseObj = {
        center: point ? point : { lat: -34.397, lng: 150.644 },
        zoom: zoom ? zoom : 8,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        clickableIcons: false
    }
    let obj = opt ? Object.assign(baseObj, opt) : baseObj;
    this.map = new google.maps.Map(document.getElementById(id), obj);
}
/** 
 * 获取所有轨迹点并画线
 * @param {Array} allData 所有轨迹点
 * @param {Object} lineObj 轨迹线的配置
 */
GoogleMap.prototype.getAllPoints = function (allData, lineObj, dom) {
    this.clearOverlays();
    this.trackDomInfo = dom;
    clearInterval(this.timer);
    this.trackData = [];//已打轨迹点
    this.allPoints = [];//全部轨迹
    this.tempPoint = allData; //全部原始数据
    for (let i = 0; i < this.tempPoint.length; i++) {
        let point = this.createPoint(this.tempPoint[i]);
        this.allPoints.push(point);
    }
    this.setViewport(this.allPoints);
    //创建第一个点
    this._drawData({icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true)
    // this.deviceMarker = this.createMarker({icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true);
}
/** 创建point
 * @param {Object} lnglat 经纬度
 */
GoogleMap.prototype.createPoint = function (lnglat) {
    var tempPoint = GPS.GPSToChina(lnglat.lat, lnglat.lng);
    return new google.maps.LatLng(tempPoint.lat, tempPoint.lng);
}
/** 设置地图中心点和放大级别
 * @param {Object} point 点
 * @param {Number} num 放大级别
 */
GoogleMap.prototype.setCenterAndZoom = function (point, num) {
    // var tempPoint = GPS.GPSToChina(point.lat, point.lng);
    this.map.setCenter(point);
    this.map.setZoom(parseInt(num));
}
/** 添加标注
 * @param {Object} lnglat 经纬度
 * @param {Object} icon 图标配置
 * @param {Object} opt 配置项
 * @param {Boolean} show 默认是否显示气泡
 * @param {Boolean} noClick 判断marker是否不可点击
 * @param {Function} callback 点击气泡之后
 */
GoogleMap.prototype.createMarker = function (lnglat, opt, dom, show, noClick, domData, callback) {
    let that = this;
    let obj = {
        lat: lnglat.lat,
        lng: lnglat.lng
    }
    let tempData = lnglat.content ? lnglat.content : {};//信息窗内容
    let point;
    if (lnglat.lng) {
        if (typeof(lnglat.lng) == 'number') {
            point = this.createPoint(obj);
        } else {
            point = lnglat;
        } 
    } else {
        point = lnglat;
    }
    let temp = {};
    if (opt) {
        if (opt.label) {
            temp.label = opt.label
        }
        if (opt.icon) {
            var image = {
                url: opt.icon.url,
                scaledSize: new google.maps.Size(opt.icon.size[0], opt.icon.size[1]),
                labelOrigin: opt.icon.labelOrigin ? new google.maps.Point(opt.icon.labelOrigin[0],opt.icon.labelOrigin[1]) : new google.maps.Point(0, 0) 
            }
            temp.icon = image
        } else {
            temp = opt;
        }
        temp.position = point;
    } else {
        temp.position = point;
    }
    let marker = new google.maps.Marker(temp);
    this.allOverlays.push(marker);
    marker.setMap(this.map);
    if (show) {
        this.showInfoWindow(marker, temp, dom);
    }
    if (!noClick) {
        google.maps.event.addListener(marker, "click", function () {
            that.createInfoWindow(marker, tempData, dom);
            if (callback) {
                callback(domData);
            }
        });
    }
    return marker;
}
/** 显示所有点的最佳地图层级 
 * @param {Array} points 所有点
*/
GoogleMap.prototype.setViewport = function (points) {
    /* var pointArr = [];
    for (var i = 0; i < points.length; i++) {
        pointArr.push(GPS.GPSToChina(points[i].lat, points[i].lng))
    } */
    var bounds = new google.maps.LatLngBounds();
    var i;
    for (i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
    }
    this.map.setCenter(bounds.getCenter());
    this.map.fitBounds(bounds);//设置最优层级
}
/** 清除制定标注
 * @param {Object} marker 标注点
 */
GoogleMap.prototype.removeMarker = function (marker) {
    marker.setMap(null);
}
/**
 * 所有标注
 */
GoogleMap.prototype.clearOverlays = function () {
    if (this.allOverlays && this.allOverlays.length > 0) {
        for (var i in this.allOverlays) {
            this.allOverlays[i].setMap(null);
        }
        this.allOverlays.length = 0;
    }
    if (this.editFence) {
        this.editFence.setMap(null);
    }
    if (this.editMarker) {
        this.editMarker.setMap(null);
    }
    if (this.editMarkerCenter) {
        this.editMarkerCenter.setMap(null);
    }
    if (this.editPath) {
        this.editPath.setMap(null);
    }
    if (this.infoWindow) {
        this.infoWindow.close();
    }
    if (this.drawTip) {
        this.drawTip = false;
    }
}
/** 关闭气泡 */
GoogleMap.prototype.closeInfoBox = function() {
    if (this.infoWindow) {
        this.infoWindow.close();
    }
}
/** 是否默认显示气泡
 * @param {Object} marker 标注点
 * @param {Object} tempData 气泡数据
 * @param {Object} dom 气泡内容
 */
GoogleMap.prototype.showInfoWindow = function(marker, tempData, dom) {
    this.createInfoWindow(marker, tempData, dom); 
}
/** 创建信息窗
 * @param {Object} marker 标注点
 * @param {Object} obj 信息窗内容
 */
GoogleMap.prototype.createInfoWindow = function (marker, obj, dom) {
    if (this.infoWindow) {
        this.infoWindow.close();
    }
    let html = '<div class="info-container">';
    for (let i in obj) {
        if (i == 'title') {
            html += '<div class="header">' + obj[i] + '</div><div class="content"><table class="table-fixed"> <tbody>';
        } else {
            html += '<tr><td>' + obj[i] + '</td></tr>';
        }
    }
    html += '</tbody></table></div>';
    let content = dom ? dom : html;
    this.infoWindow = new google.maps.InfoWindow({
        content: content,
    });
    dom.style.display = 'block';
    this.infoWindow.open(this.map, marker);
}
/** 创建轨迹线
 * @param {Object} opt 轨迹线配置项
 */
GoogleMap.prototype.createPolyline = function (pointArr, opt) {
    let obj = {
        path: pointArr && pointArr.path ? pointArr.path : [],
        strokeColor: opt && opt.strokeColor ? opt.strokeColor : '#3270FF',
        strokeOpacity: opt && opt.strokeOpacity ? opt.strokeOpacity : '1.0',
        strokeWeight: opt && opt.strokeWeight ? opt.strokeWeight : 3,
    }
    let polyLine = new google.maps.Polyline(obj);

    polyLine.setMap(this.map);
    this.allOverlays.push(polyLine);
    return polyLine;
}
/** 轨迹播放 
 * @param {Array} arr 轨迹点
 * @param {Object} opt 配置项
*/
GoogleMap.prototype.play = function (getSpeedAndPrograss) {
    this.trackCallBack = getSpeedAndPrograss;
    //判断进度条是否播放完
    if( this.trackIndex == 0) {
        this.clearOverlays();
        //创建初始点
        getSpeedAndPrograss(1);
        this._drawData({icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true)
    } 
    if (this.timer) {
        clearInterval(this.timer);
    }
    this.timer = null;
    this.timer = setInterval(() => {
        this._trackLine('line',getSpeedAndPrograss);
    }, 1000/this.trackPlaySpeed);
}
//设置轨迹播放速度
GoogleMap.prototype.setPlaySpeed = function (speed) {
    clearInterval(this.timer);
    this.timer = null;
    this.trackPlaySpeed = speed;
    if(this.trackIndex > 0) {
        this.timer = setInterval(() => {
            this._trackLine('line',this.trackCallBack);
        }, 1000/speed);
    }
    
};
//设置进度条拖动
GoogleMap.prototype.setPrograssIndex = function (prograss) {
    if(this.timer) {
        this._emptyLine();
        this.polyLine = this.createPolyline(this.allPoints[0]);
    }
    this.trackIndex = prograss;
};
/** 绘制初始轨迹点和轨迹线
 * @param {Array} arr 轨迹点
 * @param {Object} opt 配置项
 */
GoogleMap.prototype._drawData = function (opt) {
    this.deviceMarker = this.createMarker(this.allPoints[0],opt,this.trackDomInfo,true);
    this.polyLine = this.createPolyline(this.allPoints[0]);
}
/** 
 * 轨迹线播放
 * @param {String} type 判断是打点播放还是轨迹播放
 */
GoogleMap.prototype._trackLine = function (type,getSpeedAndPrograss) {
    if (type == 'line') {
        this.polyLine = this.createPolyline(this.allPoints[0]);
        let path = this.polyLine.getPath();
        this.trackData = this.allPoints.slice(0,this.trackIndex+1);
        let point = this.allPoints[this.trackIndex];
        this.deviceMarker.setPosition(point);
        this.trackData.forEach( (v,i,r) => {
            path.push(v)
        });
    } else {
        if (this.trackIndex > 1) {
            this.setMarker(this.trackIndex - 1);
        }
        this.trackData = this.allPoints.slice(0,this.trackIndex+1);
        let point = this.allPoints[this.trackIndex];
        this.deviceMarker.setPosition(point);
    }
    this.trackIndex++;
    this.trackCallBack(this.trackIndex);
    if (this.trackIndex >= this.allPoints.length) {
        clearInterval(this.timer);
        this.timer = null;
        this.trackIndex = 0;
        this.trackData = []
    }
}
/** 
 * 停止播放轨迹（重置轨迹）
 */
GoogleMap.prototype.rePause = function () {
    if (this.openInfo) {
        this.openInfo.close();
    }
    this.clearOverlays();
    this._drawData({icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true)
    this.trackIndex = 0;
    clearInterval(this.timer);
    this.timer = null;
};
/** 
 * 轨迹暂停
 */
GoogleMap.prototype.playPause = function () {
    clearInterval(this.timer);
    this.timer = null;
}
/** 
 * 轨迹重新播放
 * @param {Array} arr 轨迹点
 * @param {Object} opt 配置项
 */
GoogleMap.prototype.replay = function () {
    this.trackIndex = 0;
    this.trackData = [];
    clearInterval(this.timer);
    this._emptyLine();
    this.polyLine = this.createPolyline(this.allPoints[0]);
    this.emptyMarkers(this.markers);
    this.timer = setInterval(() => {
        this._trackLine('line');
    }, 1000);
}
/** 
 * 清空轨迹点
 * @param {Array} markers 所有轨迹标注点
 */
GoogleMap.prototype.emptyMarkers = function (markers) {
    for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
    }
}
/** 
 * 清空轨迹线
 */
GoogleMap.prototype._emptyLine = function () {
    if (this.polyLine) {
        this.polyLine.setMap(null);
        this.polyLine = null;
    }
}
/** 打点播放 
 * @param {Array} arr 轨迹点
 * @param {Object} opt 配置项
*/
GoogleMap.prototype.dotPlay = function (arr, opt) {
    if (this.timer) {
        clearInterval(this.timer);
    }
    if (arr && arr.length > 0) {
        if (!this.deviceMarker) {
            this._drawData(opt);
        }
    }
    if (this.playMode == 0) {
        this.playMode == 1;
        this.trackIndex--;
        this._emptyLine();
        this.trackIndex++;
        this.reSetPlaySchedule();
        this.trackIndex++;
    }
    this.timer = setInterval(() => {
        this._trackLine('dot')
    }, 1000);
}
/** 重设打点播放 */
GoogleMap.prototype.reSetPlaySchedule = function () {
    this.clearOverlays();
    this.trackData.push(this.trackIndex);
    for (var i = 0; i < this.trackIndex; i++) {
        let point = this.allPoints[i];
        if (i < this.trackIndex) {
            this.setMarker(i);
        }
        if (i == this.trackIndex && point) {
            this.deviceMarker.setPosition(point)
        }
    }
}
/** 设置打点播放轨迹点 
 * @param {Number} num 第几个点
*/
GoogleMap.prototype.setMarker = function (num) {
    let newMarker = this.createMarker(this.allPoints[num]);
    this.allOverlays[this.allOverlays.length] = newMarker;
}
/** 绘制围栏
 * @param {Object} markerOpt 标注点配置项
 * @param {Object} circleOpt 圆形覆盖物配置项
 * @param {Object} polygonOpt 多边形覆盖物配置项
 * @param {Object} polyLineOpt 轨迹线配置项
 * @param {Object} overComlpete 绘制完成后的回调函数
 */
GoogleMap.prototype.createFence = function (type,circleOpt, polygonOpt, overComlpete) {
    var that = this;
    this.map.setOptions({ disableDoubleClickZoom: true });
    this.drawingManager = null;
    var style = {
        fillColor: 'rgba(50, 112, 255, 0.1)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#3270FF',
        clickable: false,
        editable: true,
        enableCalculate: true,
        zIndex: 1,
    };
    var circleOptions = circleOpt ? circleOpt : style;
    var polygonOptions = polygonOpt ? polygonOpt : style;
    this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            // drawingModes: ['circle', 'polygon', 'polyline', 'rectangle']
            drawingModes: [],
        },
        circleOptions: circleOptions,
        polygonOptions: polygonOptions,
        rectangleOptions: polygonOptions
    });
    if (type == 'circle') {
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)
    } else if (type == 'polygon') {
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
    }
    var infoObj = {
        info: '单击开始，绘制完成或双击结束',
        targetDiv: 'map'
    }
    this.drawingManager.setMap(this.map);
    var temp = document.querySelector('#map').firstElementChild;
    var dom = temp.childNodes[0].firstElementChild
    dom.style.cursor = 'url(../../assets/image/map/circle.png), crosshair !important';
    this.drawTip = true;
    this._mouseAlt(infoObj);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function (event) {
        overComlpete(event);
        that.fenceArr.push(event)
        that.drawingManager.setMap(null);
        that.drawTip = false;
    });
}
/** 停止绘制
 * 
 */
GoogleMap.prototype.closeFence = function() {
    this.drawingManager.setMap(null);
}
/** 清除指定围栏
 * @param {Object} fence
 */
GoogleMap.prototype.clearFence = function(fence) {
    fence.setMap(null);
}
/** 清除围栏/轨迹线(绘制模式产生的覆盖物) 
 * @param {string} id 围栏id 
*/
GoogleMap.prototype.removeFence = function (id) {
    if (this.fenceArr) {
        if (id) {
            //移除指定围栏
            this.fenceArr[id].overlay.setMap(null);
        } else {
            //移除全部围栏
            for (var i = 0; i < this.fenceArr.length; i++) {
                this.fenceArr[i].overlay.setMap(null);
            }
        }
    }
}
/** 中国行政区域(需结合百度地图) 
 * @param {String} name 行政区域名称
*/
GoogleMap.prototype.createRegions = function (name) {
    var bdary = new BMap.Boundary();
    var that = this;
    bdary.get(name, function (rs) {
        console.log(rs);
        var rslength = rs.boundaries.length; //获取行政区域边界经纬度
        var top = 0; //大
        var bottom = 0; //小
        var left = 0; //小
        var right = 0; //大
        for (var i = 0; i < rslength; i++) {
            var triangleCoords = [];
            var temp = rs.boundaries[i];
            var latLngs = temp.split(";");
            for (var j = 1; j < latLngs.length - 1; j++) {
                var postion = latLngs[j].indexOf(",");
                var lng = parseFloat(latLngs[j].substr(0, postion));//经度
                var lat = parseFloat(latLngs[j].substr(postion + 1));//纬度
                //加入经纬度
                triangleCoords.push(new google.maps.LatLng(lat, lng));
                //活动最大，最小经纬度。计算地图中心点
                if (j == 1 && top == 0 && bottom == 0 && left == 0 && right == 0) {
                    top = lat;
                    bottom = lat;
                    left = lng;
                    right = lng;
                } else {
                    if (lng > top) {
                        top = lat;
                    }
                    if (lat < bottom) {
                        bottom = lat;
                    }
                    if (lng > right) {
                        right = lng;
                    }
                    if (lng < left) {
                        left = lng;
                    }
                }
            }
            //用转换过后的经纬度在谷歌中绘图
            var bermudaTriangle = new google.maps.Polygon({
                paths: triangleCoords,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35
            });
            bermudaTriangle.setMap(that.map);
        }
        that.setCenterAndZoom(new google.maps.LatLng(bottom + ((top - bottom) / 2), left + ((right - left) / 2)), 7);
    });
}
/** 计算面积 
 * @param {Object} overlay 覆盖物
*/
GoogleMap.prototype.computeArea = function (overlay) {
    var area = google.maps.geometry.spherical.computeArea(overlay.getPath());
    area = area > 1000 ? (area / 1000).toFixed(2) + 'km2' : area.toFixed(2) + 'm2';
    var obj = {
        label: {
            text: area,
            color: 'red'
        }
    }
    var marker = this.createMarker(overlay.getPath().getArray()[0], obj);
    marker.setMap(this.map);
    return area;
}
/** 测距
 * @param {Object} overlay 测距线
 */
GoogleMap.prototype.computeLength = function (line) {
    var that = this;
    this.drawinglength = null;
    var distance = null;
    var style = {
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
        fillOpacity: 1,
        fillWeight: 2,
    };
    this.drawinglength = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYLINE,
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [],
        },
        polylineOptions: style
        // markerOptions: style
    });
    // this.drawinglength.setDrawingMode(google.maps.drawing.OverlayType.MARKER)
    this.drawinglength.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE)
    this.drawinglength.setMap(this.map);
    var tipObj = {
        info: '点击开始测距，双击结束测距',
        targetDiv: 'map'
    }
    this.drawTip = true;
    this._mouseAlt(tipObj);
    google.maps.event.addListener(this.drawinglength, 'polylinecomplete', function (event) {
        var line = event.getPath();
        distance = google.maps.geometry.spherical.computeLength(line) 
        distance = distance >= 1000 ? (distance/1000).toFixed(2) + 'km' : distance.toFixed(2) + 'm';
        that.drawinglength.setMap(null);
        var arr = line.getArray();
        var img = that.addLabel(distance, {width: 18, height: 18});
        var opt = {
            label: {
                color: "red",                //文本颜色
                fontSize: "14px",           //文本字体大小
                text: distance,			 //需要显示的文本
            },
            icon: {
                url: img,
                size: [18, 18],
                labelOrigin: [0, -8]
            }
        }
        var marker = that.createMarker(arr[arr.length - 1],opt, null,false, true)
        /* google.maps.event.addListener(marker, "click", function () {
            event.setMap(null);
            that.removeMarker(marker);
        });  */
        if (that.drawTip) {
            that.drawTip = false;
        }
    });  
    /* var that = this;
    this.drawinglength = null;
    var distance = null;
    var style = {
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
        clickable: false,
        editable: false,
        zIndex: 1,
        icon: {
            url: emptyIcon,
            scaledSize: new google.maps.Size(12,12),
            anchor: new google.maps.Point(5, 5),
            labelOrigin: new google.maps.Point(0,-10),
        }
    };
    this.drawinglength = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [],
        },
        markerOptions: style
    });
    this.drawinglength.setDrawingMode(google.maps.drawing.OverlayType.MARKER)
    this.drawinglength.setMap(this.map);
    var pointArr = [];
    var allLen = 0;
    var path = new google.maps.Polyline({
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
    });
    var path1 = new google.maps.Polyline({
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
    });
    path.setMap(this.map);
    path1.setMap(this.map);
    var flag = false;
    google.maps.event.addListener(this.drawinglength, 'markercomplete', function (event) {
        var point = event.position;
        pointArr.push(point);
        path.setPath(pointArr);
        console.log(flag);
        var text = '起点'
        if (pointArr.length > 1) {
            var line = [pointArr[pointArr.length -2], pointArr[pointArr.length - 1]];
            var text = that._lineLen(line);
            allLen += text;
            text = text < 1000 ? Number(text).toFixed(2) + 'm' : (text / 1000).toFixed(2) + 'km';
        } else {
            text = '起点';
        }
        event.setLabel({text: text,color: '#3270FF', fontWeight: '500',fontSize: '16px'});
    }); 
    var dom = document.querySelector('#map')
    dom.addEventListener('mousemove', function(e) {
        console.log(e);
    });
    dom.addEventListener('dblclick', function() {
        that.drawinglength.setMap(null);
        flag = true
    });  */ 
    /* var path = new google.maps.Polyline({
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
    });
    var path1 = new google.maps.Polyline({
        strokeColor:"#3270FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor:'#3270FF',
    });
    path.setMap(this.map);
    path1.setMap(this.map);
    var pointArr = [];
    var allLen = 0;
    var that = this;
    var image = {
        url: emptyIcon,
        scaledSize: new google.maps.Size(12,12),
        anchor: new google.maps.Point(5, 5),
        labelOrigin: new google.maps.Point(0,-10),
    }
    google.maps.event.addListener(this.map,'click', function(e) {
        var temp = {}
        pointArr.push(e.latLng);
        path.setPath(pointArr);
        temp.icon = image;
        temp.position = e.latLng;
        var  marker = new google.maps.Marker(temp);
        marker.setMap(that.map);
        var text = '起点'
        if (pointArr.length > 1) {
            var line = [pointArr[pointArr.length -2], pointArr[pointArr.length - 1]];
            var text = that._lineLen(line);
            allLen += text;
            text = text < 1000 ? Number(text).toFixed(2) + 'm' : (text / 1000).toFixed(2) + 'km';
        } else {
            text = '起点';
        }
        marker.setLabel({text: text,color: '#3270FF', fontWeight: '500',fontSize: '16px'});
    });
    google.maps.event.addListener(this.map,'mousemove', function(e) {
        var point = e.latLng;
        console.log(point);
        if (pointArr.length > 0) {
            path1.setPath([pointArr[pointArr.length - 1], point]);
        }
        
    });
    google.maps.event.addListener(this.map,'dblclick', function(e) {
        console.log(e, 33);
    }); */
}
/** 计算距离 */ 
GoogleMap.prototype._lineLen = function(line) {
    return google.maps.geometry.spherical.computeLength(line);
}
/** 添加自定义标注 
 * @param {Object} latlng 经纬度左边
 * @param {Object} opt 配置项
*/
GoogleMap.prototype.addLabel = function (latlng, opt) {
    var div = document.createElement('div');
    var styleText = `position: absolute;width: 200px;textAlign: center;padding: 2px; fontSize: 12px;`
    div.className = opt && opt.class ? opt.class : '';
    div.innerHTML = opt && opt.text ? opt.text : '';
    div.style.cssText = opt && opt.style ? opt.style : styleText;
}
/** 
 * 清空地图
 * @param {String} id 地图容器id
 */
GoogleMap.prototype.clearMap = function(id) {
    // 获取 map容器
    var oContainer = document.getElementById(id);
    // 获取 div 标签下的所有子节点
    var pObjs = oContainer.childNodes;
    for (var i = pObjs.length - 1; i >= 0; i--) {
        oContainer.removeChild(pObjs[i]);
    }
}
/** 设置地图类型
 * @param {String} str 地图类型
 */
GoogleMap.prototype.setMapType = function(str) {
    let type = str == 'map' ? 'roadmap' : 'satellite';
    this.map.setMapTypeId(type);
}
/** 
 * 地图全屏 
*/
GoogleMap.prototype.fullScreen = function(dom) {
    //当前全屏状态
    let element = document.getElementById(dom);
    let isFullSceen=document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
    //多个游览器内核的全屏判断
    let requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen
    if (requestMethod) {
        if(isFullSceen){ //根据是否处于全屏状态 来进行切换 
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        }
        else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
        else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        if(typeof cfs != "undefined" && cfs) {
            cfs.call(el);
        }
        }else{
        requestMethod.call(element) //打开全屏
        }
    } else if (typeof window.ActiveXObject !== 'undefined') { 
        //低版本的进入全屏和取消全屏都是这个方法
        var wscript = new ActiveXObject('WScript.Shell')
        if (wscript !== null) {
        wscript.SendKeys('{F11}')
        }
    }
    //返回操作后的全屏状态 
    return !isFullSceen
}
/** 
 * 放大地图
 */
GoogleMap.prototype.zoomOut = function() {
    var zoom = this.map.getZoom();
    zoom++;
    this.map.setZoom(zoom);
}
/** 
 * 缩小地图
 */
GoogleMap.prototype.zoomIn = function() {
    var zoom = this.map.getZoom();
    zoom--;
    this.map.setZoom(zoom);
}
/** 地图中添加文字
 * @param {Object} point 点
 */
GoogleMap.prototype.addLabel = function(obj) {
    var canvas = document.createElement('canvas');
    canvas.width = obj && obj.width ? obj.width : 20;
    canvas.height = obj && obj.height ? obj.height : 30;
    var ctx = canvas.getContext('2d');
    ctx.lineWidth = 1;
    ctx.fillStyle = '#fff' // 设置填充的背景颜色
    ctx.strokeStyle = 'red';
    ctx.fillRect(0, 0, canvas.width, canvas.height) // 绘制 w*h 像素的已填充矩形：
    ctx.strokeRect(0, 0, canvas.width, canvas.height) // 绘制 w*h 像素的已填充矩形：
    ctx.fillStyle ='red';
    ctx.font ='14px sans-serif';
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillText('X', canvas.width / 2, canvas.height / 2, canvas.width);
    return canvas.toDataURL("image/png");
}
/** 
 * 提示框随鼠标移动 
 */
GoogleMap.prototype._mouseAlt = function(opt) {
    var that = this;
    var box = document.getElementById(opt.targetDiv);
    if (!this.drawTip) {
        document.body.removeChild(document.getElementById("moualt"));
        return false;
    }
    box.onmouseover = function(){
        var moualt = document.createElement("div");
        document.body.appendChild(moualt);
        moualt.setAttribute("id","moualt");
    };
    box.onmousemove = function(ev){
        ev = ev || window.event;
        var mousePos = that._mousePosition(ev);
        if (!that.drawTip) {
            var dom =document.getElementById("moualt");
            if (dom) {
                document.body.removeChild(dom);
            }
            return false;
        }
        var a = document.getElementById("moualt");
        a.innerHTML = opt.info;
        a.style.cssText = "position:absolute;z-index:1000;padding:0 4px;background-color:#fff;border: 1px solid #3270FF;color: #262626;font-size:12px;border-radius:2px";
        a.style.left = mousePos.x + 10 + "px";
        a.style.top = mousePos.y + 10 + "px";
    };
    box.onmouseout = function(){
        var dom = document.getElementById("moualt");
        if (dom) {
            document.body.removeChild(dom);
        }
    }
}
/** 
 * 监听鼠标移动
 */
GoogleMap.prototype._mousePosition = function(ev) {
    if(ev.pageX || ev.pageY){
        return { x:ev.pageX,y:ev.pageY}
    }
    return{
        x:ev.clientX+document.body.scrollLeft-document.body.clientLeft,
        Y:ev.clientY+document.body.scrollTop-document.body.clientTop
    };
}
/** 显示多边形
 * @param {Object} points 点集合
 * @param {Object} styleObj 样式
 */
GoogleMap.prototype.showPolygon = function(points, styleObj) {
    var pointArr = [];
    for (var i = 0; i < points.length; i++) {
        pointArr.push(GPS.GPSToChina(points[i].lat, points[i].lng))
    }
    var obj = {
        paths: pointArr,
        strokeColor: styleObj && styleObj.strokeColor ? styleObj.strokeColor :'#3270FF',
        strokeWeight: styleObj && styleObj.strokeWeight ? styleObj.strokeWeight : 3,
        fillColor: styleObj && styleObj.fillColor ? styleObj.fillColor : '#3270FF',
        fillOpacity: styleObj && styleObj.fillOpacity ? styleObj.fillOpacity : 0.1,
        editable: styleObj && styleObj.editable ? styleObj.editable : false,
    }
    this.setViewport(pointArr);
    var polygon = new google.maps.Polygon(obj);
    polygon.setMap(this.map);
    return polygon;
}
/** 显示圆形覆盖物
 * @param {Object} point 中心点
 * @param {String} radius 半径
 * @param {Object} styleObj 样式
 */
GoogleMap.prototype.showCircle = function(point, radius, styleObj) {
    var tempPoint = GPS.GPSToChina(point.lat, point.lng);
    var obj = {
        center: tempPoint,
        radius: radius,
        strokeColor: styleObj && styleObj.strokeColor ? styleObj.strokeColor :'#3270FF',
        strokeWeight: styleObj && styleObj.strokeWeight ? styleObj.strokeWeight : 3,
        fillColor: styleObj && styleObj.fillColor ? styleObj.fillColor : '#3270FF',
        fillOpacity: styleObj && styleObj.fillOpacity ? styleObj.fillOpacity : .1,
        // editable: styleObj && styleObj.editable ? styleObj.editable : false,
        editable: false,
    }
    var circle = new google.maps.Circle(obj);
    // console.log(circle.getFeatures(),66);
    circle.setMap(this.map);
    return circle;
}
/** 更改覆盖物后的回调函数
 * @param {Object} fence 覆盖物
 * @param {String} type 覆盖物类型
 */
GoogleMap.prototype.updateCallback = function(fence, callback, type) {
    this.editFence = fence;
    var that = this;
    if (type == 'circle') {
        var tipObj = {
            info: '拖拽点更改围栏半径，释放完成',
            targetDiv: 'map'
        }
        this.drawTip = true;
        this._mouseAlt(tipObj);
        var oldCenter = fence.getCenter();
        var point1 = fence.getBounds().getNorthEast();
        var point2 = fence.getBounds().getSouthWest();
       this.setViewport([point1, point2]);
       var oldRadius = fence.getRadius();
        oldRadius = oldRadius < 1000 ? Number(oldRadius).toFixed(2) + 'm' : (oldRadius /1000).toFixed(2) + 'km';
        var temp = {
            position: oldCenter,
            zIndex: 10,
            fillColor: '#3270FF',
        }
        var image = {
            url: centerIcon,
            scaledSize: new google.maps.Size(12,12),
            anchor: new google.maps.Point(5, 5),
            labelOrigin: new google.maps.Point(0,-10),
        }
        temp.icon = image;
        temp.label = {
            text: oldRadius,
            color: '#3270FF',
            fontWeight: '500',
            fontSize: '16px'
        }
        this.editMarkerCenter= new google.maps.Marker(temp);
        this.editMarkerCenter.setMap(this.map);
        var point3 = {lat: oldCenter.toJSON().lat, lng: point1.toJSON().lng};//获取圆边界上的点
        var temp2 = {
            position: point3,
            zIndex: 10,
            draggable: true
        }
        var image2 = {
            url: emptyIcon,
            scaledSize: new google.maps.Size(12,12),
            anchor: new google.maps.Point(5, 5),
        }
        temp2.icon = image2;
        this.editMarker = new google.maps.Marker(temp2);
        this.editMarker.setMap(this.map);
        this.editPath = new google.maps.Polyline({
            path: [oldCenter, point3] ,
            strokeColor:"#3270FF",
            strokeOpacity: 0,
            strokeWeight: 2,
            icons:[{icon:{path: 'M 0,-1 0,1',strokeOpacity: 1,scale: 3},offset: '0',repeat: '20px'}],
        });
        this.editPath.setMap(that.map);
        google.maps.event.addListener(this.editMarker, 'drag', function(e) {
            that.editPath.setPath([oldCenter, e.latLng]);
            var radius = google.maps.geometry.spherical.computeLength([oldCenter, e.latLng]);
            var text = radius < 1000 ? Number(radius).toFixed(2) + 'm' : (radius / 1000).toFixed(2) + 'km';
            that.editMarkerCenter.setLabel({text: text,color: '#3270FF', fontWeight: '500',fontSize: '16px'});
            fence.setRadius(radius);
        });
        google.maps.event.addListener(this.editMarker, 'dragend', function(e) {
            var radius = google.maps.geometry.spherical.computeLength([oldCenter, e.latLng]);
            var point = fence.getCenter().toJSON();
            var pointGps = GPS.GPSToChina(point.lat, point.lng);
            var obj = {
                center: oldCenter.toJSON(),
                radius: radius,
            }
            callback(obj, type);
        });
    } else if (type == 'polygon') {
        var tipObj = {
            info: '按住鼠标左键拖拽点更改半径，松开鼠标确定围栏',
            targetDiv: 'map'
        }
        this.drawTip = true;
        this._mouseAlt(tipObj);
        google.maps.event.addListener(fence.getPath(),'insert_at', function() {
            var pointList = [];
            let tempPointList = fence.getPath().getArray();
            tempPointList.forEach(item => {
                var point = item.toJSON();
                var pointGps = GPS.GPSToChina(point.lat, point.lng);
                pointList.push(pointGps);
            });
            callback(pointList, type);
        });
        google.maps.event.addListener(fence.getPath(),'set_at', function() {
            var pointList = [];
            let tempPointList = fence.getPath().getArray();
            tempPointList.forEach(function(item) {
                var point = item.toJSON();
                var pointGps = GPS.GPSToChina(point.lat, point.lng);
                pointList.push(pointGps);
            });
            callback(pointList, type);
        });
        google.maps.event.addListener(fence.getPath(),'remove_at', function() {
            var pointList = [];
            let tempPointList = fence.getPath().getArray();
            tempPointList.forEach(item => {
                var point = item.toJSON();
                var pointGps = GPS.GPSToChina(point.lat, point.lng);
                pointList.push(pointGps);
            });
            callback(pointList, type);
        });
    }
    
}
export default GoogleMap;