<template>
    <div class="map-type clearfix">
        <div class="left">
            <el-select class="tool-select tool-bar-select" v-model="toolBar" placeholder="工具箱">
                <span slot="prefix" class="tool-bar"></span>
                <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.value"
                    :value="item.name" @click.native="selectChange">
                    <span :class="['tool-span', item.name]"></span>
                    <span class="opt-value">{{item.value}}</span>
                </el-option>
            </el-select>
        </div>
        <div class="left">
            <el-select class="tool-select map-select" v-model="selectMap" placeholder="请选择地图">
                <el-option
                    v-for="item in mapOptions"
                    :key="item.name"
                    :label="item.value"
                    :value="item.name" @click.native="selectChangeMap">
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                toolBar: '',//工具栏
                options: [
                    /* {name: 'tool', value: '工具箱', icon: 1}, */
                    {name: 'ranging', value: '测距', icon: 2},
                    {name: 'fullScreen', value: '全屏',icon: 3},
                ],
                mapOptions: [
                    {name: 'google', value: '谷歌地图'},
                    {name: 'baidu', value: '百度地图'},
                ],
                selectMap: 'baidu',//地图
                mapType: 'baidu',//地图初始类型
                sameMapType: 'map',//地图类型
            };
        },
        methods: {
            /** 下拉框切换
             *@param {String} val 选中的值 
             */
            selectChange(val) {
                this.$emit('selectChange', this.toolBar);
            },
            /** 地图切换
             * @param {String} val 选择的地图
             */
            selectChangeMap(val) {
                this.toolBar = '';
                this.$emit('selectChangeMap', this.selectMap);
            },
        }
    }
</script>
<style scoped lang="scss">
    .map-type {
        position: absolute;
        top: 46px;
        right: 32px;
        z-index: 1;
        &.clearfix {
            zoom: 1;
            &:after {
                display: block;
                content: '';
                width: 0;
                height: 0;
                visibility: hidden;
                clear: both;
            }
        }
        .left {
            float: left;
            
            .tool-select {
                width: 130px;
                height: 32px;
                margin-left: 12px;
            }
            .map-select {
                width: 108px;
            }
            .switch-type {
                margin-top: 5px;
            }
        }
    }
</style>
<style>
    .BMap_mask,
    .map-div,
    .map {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .tool-select .el-input__inner,
    .map-select .el-input__inner {
        height: 32px;
        color:#262626;
        border: 0;
        box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08);
    }
    .map-select .el-input__icon {
        line-height: 32px;
    }
    .map-select.el-select .el-input.is-focus .el-input__inner {
        border-color: transparent;
    }
    .map-select .el-input__prefix,
    .tool-select .el-input__prefix {
        display: flex;
        align-items: center;
    }
    .map-select .tool-bar {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/image/map/tool.png);
    }
    .el-select:hover .el-input__inner,
    .el-select-dropdown__item.selected,
    .el-select-dropdown__item:hover,
    .tool-bar-select:hover input::-webkit-input-placeholder {
        color: #3270FF;
    }
    .tool-select:hover .tool-bar {
        background: url(../../assets/image/map/tool-select.png);
    }
    .tool-bar-select input::-webkit-input-placeholder {
        color: #262626;
    }
    .tool-bar-select .el-input__inner {
        padding-left: 42px;
    }
    .el-select .el-input__suffix {
        margin-right: 4px;
        line-height: 32px;
    }
    .el-select .el-input .el-select__caret {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle; 
        background: url(../../assets/image/map/icon-down.png) no-repeat;
    }
    .el-select .el-icon-arrow-up:before {
        content: '';
    }
    .el-select-dropdown__item {
        display: flex;
        align-items: center;
    }
    .el-select-dropdown__item .tool-span {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
    .el-input__prefix .tool-bar {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        background: url(../../assets/image/map/tool.png);
    }
    .el-select .el-input .el-select__caret {
        margin-right: 4px;
    }
    .el-select-dropdown__item.selected .ranging,
    .el-select-dropdown__item:hover .ranging {
        background: url(../../assets/image/map/distance-select.png);
    }
    .el-select-dropdown__item .ranging {
        background: url(../../assets/image/map/distance.png);
    }
    .el-select-dropdown__item.selected .fullScreen, 
    .el-select-dropdown__item:hover .fullScreen {
        background: url(../../assets/image/map/full-select.png);
    }
    .el-select-dropdown__item .fullScreen {
        background: url(../../assets/image/map/full.png);
    }
    /* .gmnoprint {
        display: none;
    } */
</style>